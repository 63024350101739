import React, { useState } from 'react'
import history from 'history.js'
import { IconButton, InputAdornment, Input } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const MatxSearchBox = () => {
    const [searchInput, setSearchInput] = useState('')

    const searchChange = (event) => {
        setSearchInput(event.target.value)
    }

    const certSearch = () => {
        history.push({
            pathname: `/identities`,
            state: { keyword: searchInput }
        })
        setSearchInput('')
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            certSearch();
        }
    }

    return (
        <div>
            <Input
                placeholder='Search identity'
                type='text'
                value={searchInput}
                onChange={searchChange}
                onKeyDown={handleKeyDown}
                className='pl-2'
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton onClick={certSearch}>
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    );
}

export default MatxSearchBox
