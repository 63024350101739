import React from 'react'
import { Router, Switch, Route, BrowserRouter } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import AuthGuard from './auth/AuthGuard'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { SnackbarProvider } from 'notistack'
import Grow from '@material-ui/core/Grow'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import IdleTimerContainer from '../app/components/IdleTimer/IdleTimerContainer';
import Cookies from 'js-cookie';

console.error = () => {};

const App = () => {
    const idToken = Cookies.get('idToken');
    const notistackRef = React.createRef();

    const onClickDismiss = key => () => {
        notistackRef.current.handleDismissSnack();
    }

    return (
        <AppContext.Provider value={{ routes}}>
            <SettingsProvider>
                <SnackbarProvider
                    ref={notistackRef}
                    className='mt-15'
                    preventDuplicate
                    hideIconVariant
                    maxSnack={1}
                    autoHideDuration={3000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    action={(key) => (
                        <HighlightOffIcon className='cursor-pointer mr-2' onClick={onClickDismiss(key)} />
                    )}
                    TransitionComponent={Grow}>
                    <MatxTheme>
                        <GlobalCss />
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <Router history={history}>
                                <AuthProvider>
                                    {idToken && <IdleTimerContainer />}
                                    <MatxSuspense>
                                        <Switch>
                                            {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                            {sessionRoutes.map((item, i) => (
                                                <Route
                                                    key={i}
                                                    exact path={item.path}
                                                    component={ () => <item.component routeId={i}/>}
                                                />
                                            ))}
                                            {/* AUTH PROTECTED DASHBOARD PAGES */}
                                            <AuthGuard>
                                                <MatxLayout />{' '}
                                                {/* RETURNS <Layout1/> component */}
                                            </AuthGuard>
                                        </Switch>
                                    </MatxSuspense>
                                </AuthProvider>
                            </Router>
                        </BrowserRouter>
                    </MatxTheme>
                </SnackbarProvider>
            </SettingsProvider>
        </AppContext.Provider>
    )
}

export default App
