import React, { useState } from 'react'
import { Button, Card, Grid, CircularProgress, Icon } from '@material-ui/core'
import CBOR from 'cbor-js'
import { getPublickey } from '../../services/awsServices'
import history from 'history.js'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const useStyles = makeStyles(() => ({
    label: {
        textAlign: 'right',
        padding: '10px 10px 0 0',
    },
    copyIcon: {
        cursor: 'pointer',
        margin: '0 0 0 5px'
    },
    copiedText: {
        cursor: 'pointer',
        paddingBottom: '10px',
        fontSize: '15px',
        color: 'green',
        verticalAlign: 'super',
    },
    credentials: {
        backgroundColor: '#E5E5E5',
        padding: '10px',
        borderRadius: '5px',
        overflowWrap: 'break-word',
    }
}));

const Yubikey = () => {
    const [credId, setCredId] = useState('')
    const [pubKey, setPubkey] = useState('')
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const [isCopied, setIsCopied] = useState({ id: false, key: false })

    const decodeAttestationObject = async (attestationObject) => {
        const attestationData = CBOR.decode(attestationObject)
        const dataView = new DataView(new ArrayBuffer(2))
        const idLenBytes = attestationData.authData.slice(53, 55)
        idLenBytes.forEach((value, index) => dataView.setUint8(index, value))
        const credentialIdLength = dataView.getUint16()
        // const credentialId = attestationData.authData.slice(55, credentialIdLength)
        const publicKeyBytes = attestationData.authData.slice(55 + credentialIdLength)
        return CBOR.decode(publicKeyBytes.buffer)
    }

    const decodeClientJSON = async (clientDataJSON) => {
        const decoder = new TextDecoder('utf-8')
        return JSON.parse(decoder.decode(clientDataJSON))
    }

    const constructPublicKeyObject = async (credential, decodedClientJSON, decodedAttestationObject) => {
        return {
            'id': credential.id,
            'type': credential.type,
            'client_type': decodedClientJSON.type,
            'client_origin': decodedClientJSON.origin,
            'client_challenge': decodedClientJSON.challenge,
            'key_type': decodedAttestationObject[1],
            'key_algorithm': decodedAttestationObject[3],
            'key_curve_type': decodedAttestationObject[-1],
            'key_curve_x': btoa(String.fromCharCode.apply(null, decodedAttestationObject[-2])),
            'key_curve_y': btoa(String.fromCharCode.apply(null, decodedAttestationObject[-3]))
        }
    }

    const processYubiKey = async () => {
        const credential = await navigator.credentials.create({
            publicKey: {
                challenge: new Uint8Array(16),
                rp: {
                    name: "Example CORP",
                    id: window.location.host
                },
                user: {
                    id: new Uint8Array(16),
                    name: "jdoe@example.com",
                    displayName: "John Doe"
                },
                pubKeyCredParams: [
                    {
                        type: "public-key",
                        alg: -7
                    }
                ],
                authenticatorSelection: {
                    authenticatorAttachment: "cross-platform",
                },
                timeout: 60000,
                attestation: "direct"
            }
        })
        const decodedClientJSON = await decodeClientJSON(credential.response.clientDataJSON)
        const decodedAttestationObject = await decodeAttestationObject(credential.response.attestationObject)
        const publicKeyObject = await constructPublicKeyObject(credential, decodedClientJSON, decodedAttestationObject)
        const publicKeyData = {
            'x': publicKeyObject['key_curve_x'],
            'y': publicKeyObject['key_curve_y']
        }
        setCredId(publicKeyObject.id)
        setLoading(true)
        const response = await getPublickey(publicKeyData)
        if (response.data.message) {
            enqueueSnackbar(response.data.message, {
                variant: 'info',
            })
        } else {
            setPubkey(response.data)
        }
        setLoading(false)
        return response.data
    }

    const copyTextToClipboard = async (copiedText) => {
        if ('clipboard' in navigator) return await navigator.clipboard.writeText(copiedText);
    }

    const handleCopyClick = (copiedValue, props) => {
        copyTextToClipboard(copiedValue)
            .then(() => {
                if (props === 'id') {
                    setIsCopied({ id: true });
                    setTimeout(() => {
                        setIsCopied({ id: false });
                    }, 1500);
                } else {
                    setIsCopied({ key: true });
                    setTimeout(() => {
                        setIsCopied({ key: false });
                    }, 1500);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className='m-sm-30'>
            <Card elevation={3}>
                <div className='p-8 h-full bg-light-gray relative'>
                    <span className="card-title">Generate Yubikey ID</span>
                    <hr />
                    <Button
                        className='my-3'
                        color='primary'
                        variant='contained'
                        onClick={processYubiKey}> Generate
                    </Button>
                    <Grid container direction='row' className='my-5'>
                        <Grid item xs={2} className={classes.label}>
                            <label>
                                <strong>Credential Id:</strong>
                            </label>
                        </Grid>
                        <Grid item xs={10} className={`${credId && classes.credentials}`}>
                            <div>
                                <span >{credId}</span>
                                {credId && <FileCopyIcon className={classes.copyIcon} onClick={() => handleCopyClick(credId, 'id')} />}
                                <span className={classes.copiedText}>{isCopied.id && 'Copied!'}</span>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className='my-5'>
                        <Grid item xs={2} className={classes.label}>
                            <label>
                                <strong>Public Key:</strong>
                            </label>
                        </Grid>
                        {loading ?
                            <Grid item xs={10} >
                                <CircularProgress size={22} />
                            </Grid>
                            :
                            <Grid item xs={10} className={`${pubKey && classes.credentials}`}>
                                <span >{pubKey}</span>
                                {pubKey && <FileCopyIcon className={classes.copyIcon} onClick={() => handleCopyClick(pubKey, 'key')} />}
                                <span className={classes.copiedText}>{isCopied.key && 'Copied!'}</span>
                            </Grid>
                        }
                    </Grid>
                    <div>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => history.push('/')}>
                            <Icon>arrow_left</Icon> Back to login
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default Yubikey
