import React from 'react';
import { IdleTimer } from './IdleTimer';
import useAuth from 'app/hooks/useAuth';

const IdleTimerContainer = () => {
    const { logout } = useAuth();
    const onIdle = () => logout();

    return (
        <IdleTimer
            timeout={5400000}  // 90 minutes timeout for trigger to logout due to user being idle
            crossTab={{
                emitOnAllTabs: true,
            }}
            debounce={250}
            onIdle={onIdle}
        />
    );
}

export default IdleTimerContainer;