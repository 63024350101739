import React from 'react'
import { authRoles } from '../../auth/authRoles'

const dashboardRoutes = [
    {
        path: '/dashboard',
        component: React.lazy(() => import('./Analytics')),
        auth: authRoles.sa,
    },
    {
        path: '/utilities',
        component: React.lazy(() => import('./Utilities.jsx')),
        auth: authRoles.sa,
        exact: true
    },
    {
        path: '/utilities/hexValueConverter',
        component: React.lazy(() => import('./HexValueConverter')),
        auth: authRoles.sa,
    },
    {
        path: '/utilities/signerGroups',
        component: React.lazy(() => import('./SignerGroups')),
        auth: authRoles.sa,
    },
    {
        path: '/utilities/cognitoUsers',
        component: React.lazy(() => import('./CognitoUsers')),
        auth: authRoles.sa,
    },
    {
        path: '/utilities/lwpkiFileParser',
        component: React.lazy(() => import('./LWPKIFileParser')),
        auth: authRoles.sa,
    }
]

export default dashboardRoutes
