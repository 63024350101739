import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const identitiesRoutes = [
    {
        path: '/identities',
        exact: true,
        component: React.lazy(() => import('./Identities'))
    },
    {
        path: '/identities/create',
        component: React.lazy(() => import('./create/CreateIdentity'))
    },
    {
        path: '/identities/identityStatus',
        component: React.lazy(() => import('./IdentityStatus'))
    },
    {
        path: '/identities/importIdentities/:transactionId',
        component: React.lazy(() => import('./ImportIdentities'))
    },
    {
        path: '/identities/importError',
        component: React.lazy(() => import('./ImportIdentitiesError'))
    },
    {
        path: '/identities/:chainSpec',
        component: React.lazy(() => import('./ViewChain'))
    }
]

export default identitiesRoutes
