import React from 'react'

const MatxLogo = () => {

    return (
        <img
            width="24px"
            height="24px"
            src="https://www4.enphase.com/sites/all/themes/enphase/assets/images/svgs/dist/enphase-e-logo-footer-orange.svg"
            alt='enphase-e-logo-footer-orange.svg'
        />
    )
}

export default MatxLogo;
