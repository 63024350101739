import React, { useState, useEffect} from 'react'
import { Card, Grid, Button, CircularProgress, Popover } from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import { useSnackbar } from "notistack";
import { authenticateUser, changePassword } from '../../../cognitoAuth/Cognito'
import { useLocation } from 'react-router-dom'
import localStorageService from 'app/services/localStorageService'
import { RedirectPath, SourcePath } from 'app/constants'
import environmentInfo from '../../../../config'
import Cookies from 'js-cookie';
import { encryptPassword } from 'app/services/encryptData'

const useStyles = makeStyles(() => ({
    cardHolder: {
        background: '#F5F5F7',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    buttonRight: {
        float: 'right'
    },
    pswdPolicy: {
        borderBottom: '1px solid #cfcfcf',
        textShadow: '2px 2px 10px #c7c7c7',
        padding: '15px'
    },
    policyList: {
        textShadow: '2px 2px 10px #c7c7c7',
        listStyleType: 'circle'
    }
}))

const JwtLogin = ({routeId}) => {
    
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
        newPassword: '',
        name: '',
    })
    const { login } = useAuth()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isForceToReset, setIsForceToReset] = useState(false)
    const classes = useStyles()
    const path = localStorageService.getItem(RedirectPath);
    const [, setRedirectPath] = useState('');
    const { pathname } = useLocation();

    localStorageService.setItem(SourcePath, pathname);

    useEffect(() => {
        closeSnackbar();
        path === null ? setRedirectPath('/dashboard/default') : setRedirectPath(path);
    }, [path]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        temp[name] = value
        setUserInfo(temp)
    }


    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleResetFormSubmit = async (event) => {
        setLoading(true)
        try {
            changePassword(userInfo.email, encryptPassword(userInfo.password), encryptPassword(userInfo.newPassword), async (err, result1) => {
                if (err) {
                    setLoading(false)
                    enqueueSnackbar(err.message || 'Password reset failed', { variant: 'error' });
                    return;
                }
                setLoading(false)
                setIsForceToReset(false)
                
                setTokenCookie('accessToken', result1.accessToken);
                setTokenCookie('refreshToken', result1.refreshToken);
                setTokenCookie('idToken', result1.idToken);
                
                await login(result1);
                window.location.replace('/dashboard/default');
            });
        } catch (error) {
            setLoading(false);
            removeTokenCookies();
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }


    function setTokenCookie(name, value) {
        Cookies.set(name, value, {
          expires: 720 / 1440, // 720 minutes - 12 hours expiration
          secure: true,
          sameSite: 'Strict',
        });
      }

    function removeTokenCookies() {
        Cookies.remove('accessToken');
        Cookies.remove('idToken');
        Cookies.remove('refreshToken');
    }

    const handleFormSubmit = async (event) => {
        setLoading(true);
        try {
            const result1 = await authenticateUser(userInfo.email, encryptPassword(userInfo.password));
            if (result1.forceToReset) {
                setLoading(false);
                setIsForceToReset(true);
            } else {
                localStorageService.setItem("firstLoadDone", false)
                localStorageService.setItem("showJwtToken", true)
                
                await login(result1.data);

                let url = window.location.protocol + '//' + window.location.hostname
                const urlRoute = routeId === 1 ? '/jwt-token' : '/dashboard/default';
                if (window.location.port) {
                    url = (url + ':' + window.location.port);
                }
                url += urlRoute
                window.location.replace(url);

            }
        } catch (error) {
            setLoading(false);
            removeTokenCookies();
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className={`p-8 bg-light-gray ${classes.textAlignCenter}`}>
                            <img
                                className="w-200"
                                src="https://www4.enphase.com/sites/all/themes/enphase/assets/images/svgs/src/enphase-logo.svg"
                                alt=""
                            />
                        </div>
                        {isForceToReset ?
                            <>
                                <h4 className={` bg-light-gray ${classes.textAlignCenter}`}>
                                    Force to reset your password
                                </h4>
                                <div className="p-8 h-full bg-light-gray relative">
                                    <ValidatorForm onSubmit={handleResetFormSubmit} container='true' onError={() => null} autoComplete='off'>
                                        <TextValidator
                                            className="mb-6 w-full min-w-288"
                                            variant="outlined"
                                            size="small"
                                            label="Email"
                                            onChange={handleChange}
                                            type="email"
                                            name="email"
                                            value={userInfo.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={[
                                                'This field is required',
                                                'Email is not valid',
                                            ]}
                                        />
                                        <TextValidator
                                            className="mb-6 w-full min-w-288"
                                            label="Old Password"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            name="password"
                                            type="password"
                                            value={userInfo.password}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                        />
                                        <TextValidator
                                            className="mb-6 w-full min-w-288"
                                            label="New Password"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            onClick={handleOpen}
                                            name="newPassword"
                                            type="password"
                                            value={userInfo.newPassword}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                        />
                                        <Popover
                                            className='ml-10'
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            PaperProps={{ elevation: 10 }}
                                            disableAutoFocus={true}
                                            disableEnforceFocus={true}
                                            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                                        >
                                            <div>
                                                <p className={classes.pswdPolicy}>New password should contain</p>
                                                {environmentInfo?.passwordPolicies.map((policy, index) => (
                                                    <ul key={index} className={classes.policyList}>
                                                        <li>{policy}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </Popover>
                                        <TextValidator
                                            className="mb-6 w-full min-w-288"
                                            label="Name"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            name="name"
                                            type="text"
                                            value={userInfo.name}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                        />
                                        <div className={`flex flex-wrap items-center mb-6 ${classes.buttonRight}`}>
                                            <div className="relative">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={loading}
                                                    type="submit"
                                                >
                                                    Change Password
                                                </Button>
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={
                                                            classes.buttonProgress
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </>
                            :
                            <>  
                                <h4 className={` bg-light-gray ${classes.textAlignCenter}`}>
                                    Sign in as cognito user
                                </h4>
                                <div className="p-8 h-full bg-light-gray relative">
                                    <ValidatorForm onSubmit={handleFormSubmit} container='true' onError={() => null} autoComplete='off'>
                                        <TextValidator
                                            className="mb-6 w-full min-w-288"
                                            variant="outlined"
                                            size="small"
                                            label="Email"
                                            onChange={handleChange}
                                            type="email"
                                            name="email"
                                            value={userInfo.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={[
                                                'This field is required',
                                                'Email is not valid',
                                            ]}
                                        />
                                        <TextValidator
                                            className="mb-6 w-full min-w-288"
                                            label="Password"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            name="password"
                                            type="password"
                                            value={userInfo.password}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                        />
                                        <div className={`flex flex-wrap items-center mb-6 ${classes.buttonRight}`}>
                                            <div className="relative">
                                                <Button
                                                    className="capitalize"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={loading}
                                                    type="submit"
                                                >
                                                    Sign in
                                                </Button>
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={
                                                            classes.buttonProgress
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </>
                        }
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default JwtLogin

