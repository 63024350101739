import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const transactionsRoutes = [
    {
        path: '/transactions',
        exact: true,
        component: React.lazy(() => import('./Transactions'))
    }
]

export default transactionsRoutes
