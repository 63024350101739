
export const auth0Config = {
    client_id: 'XmminWIs0S8gR3gIRBydYLWbF58x81vK',
    domain: 'matx.us.auth0.com',
}

let api_gateway = '';
let public_key = '';
let public_key_pem = '';
let policies = [];


if (process.env.REACT_APP_ENVIRONMENT === 'test') {
    api_gateway = 'https://cca-api-test.enphaseenergy.com';

} else if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    api_gateway = 'http://localhost:8080';
    public_key = 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQm9qQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FZOEFNSUlCaWdLQ0FZRUEydjdOUGFIaUF1RjZVZFJRU2l2VAo1SGlOaDdjaE5MZCtkcFRtNitkNTBpdURjSGlyQ1lGdlZYY2lJUVorTXRrdk9McVFrQ214OTJCVVpNTDdZUS9ZCmZOVCtFS2t0YTJMVEF2dlZpNCtWeXFIZnE3U1hOMDJ3YWxzaG1NeW8vVzVBMndxK08zRHlxRjErUEMvS1pUSmEKSFNaMldXZDY5VWltdG5DQkdHTjhhZDZ3WlRzNW5RdGxLRXlJblBQZGFyWDBLbmF5U2FxZmJUK3o5bUNmaXJ2MApJS3JEeitCK0txd3kyQTNOSU1KSkNvQ0xSL3oxL2JvRFcyYWpPTWd1YkJsN0ZkTUx4dDFuTytBYUVNbkR1bUxwCjRLcHNFMm1HdjVQZ29ZNk1wcElueWVRV0V5bHNlM0ZMNm9XZTl6Z2ZqaXJ5SVB4b0p6eUhrNXMrR2xuZTE3ZWcKazJMNUVLK3p6M01KV0gvTUVrcjdQNzNhS1BlUzRWWjRWSXd2cFVYcjVYUTNqUldLWnpMVXRRRWR4N0EzbTVWeApkcm83SkhaUDFPTzZRaFRKUjc0NkJRcjZ1Yml6U2RJQURzNVV1K1Bpa1J4eStPdnhqcWpMWGlMZjY5c2pzRXYwCmtpbzM2clc5bytONS9TdHo1N0dOODliWllGMUwyc01PdkZvL0F1bDJ6N2tiQWdNQkFBRT0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tCg=='
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
}  
else if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    api_gateway = 'https://cca-api-dev.enphaseenergy.com';
    public_key = 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQm9qQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FZOEFNSUlCaWdLQ0FZRUEydjdOUGFIaUF1RjZVZFJRU2l2VAo1SGlOaDdjaE5MZCtkcFRtNitkNTBpdURjSGlyQ1lGdlZYY2lJUVorTXRrdk9McVFrQ214OTJCVVpNTDdZUS9ZCmZOVCtFS2t0YTJMVEF2dlZpNCtWeXFIZnE3U1hOMDJ3YWxzaG1NeW8vVzVBMndxK08zRHlxRjErUEMvS1pUSmEKSFNaMldXZDY5VWltdG5DQkdHTjhhZDZ3WlRzNW5RdGxLRXlJblBQZGFyWDBLbmF5U2FxZmJUK3o5bUNmaXJ2MApJS3JEeitCK0txd3kyQTNOSU1KSkNvQ0xSL3oxL2JvRFcyYWpPTWd1YkJsN0ZkTUx4dDFuTytBYUVNbkR1bUxwCjRLcHNFMm1HdjVQZ29ZNk1wcElueWVRV0V5bHNlM0ZMNm9XZTl6Z2ZqaXJ5SVB4b0p6eUhrNXMrR2xuZTE3ZWcKazJMNUVLK3p6M01KV0gvTUVrcjdQNzNhS1BlUzRWWjRWSXd2cFVYcjVYUTNqUldLWnpMVXRRRWR4N0EzbTVWeApkcm83SkhaUDFPTzZRaFRKUjc0NkJRcjZ1Yml6U2RJQURzNVV1K1Bpa1J4eStPdnhqcWpMWGlMZjY5c2pzRXYwCmtpbzM2clc5bytONS9TdHo1N0dOODliWllGMUwyc01PdkZvL0F1bDJ6N2tiQWdNQkFBRT0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tCg=='
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
} else if (process.env.REACT_APP_ENVIRONMENT === 'qa') {
    api_gateway = 'https://muensyds37-vpce-0b83d958ffbf00ba8.execute-api.us-east-1.amazonaws.com/cca_qa';
    public_key = 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQm9qQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FZOEFNSUlCaWdLQ0FZRUEydjdOUGFIaUF1RjZVZFJRU2l2VAo1SGlOaDdjaE5MZCtkcFRtNitkNTBpdURjSGlyQ1lGdlZYY2lJUVorTXRrdk9McVFrQ214OTJCVVpNTDdZUS9ZCmZOVCtFS2t0YTJMVEF2dlZpNCtWeXFIZnE3U1hOMDJ3YWxzaG1NeW8vVzVBMndxK08zRHlxRjErUEMvS1pUSmEKSFNaMldXZDY5VWltdG5DQkdHTjhhZDZ3WlRzNW5RdGxLRXlJblBQZGFyWDBLbmF5U2FxZmJUK3o5bUNmaXJ2MApJS3JEeitCK0txd3kyQTNOSU1KSkNvQ0xSL3oxL2JvRFcyYWpPTWd1YkJsN0ZkTUx4dDFuTytBYUVNbkR1bUxwCjRLcHNFMm1HdjVQZ29ZNk1wcElueWVRV0V5bHNlM0ZMNm9XZTl6Z2ZqaXJ5SVB4b0p6eUhrNXMrR2xuZTE3ZWcKazJMNUVLK3p6M01KV0gvTUVrcjdQNzNhS1BlUzRWWjRWSXd2cFVYcjVYUTNqUldLWnpMVXRRRWR4N0EzbTVWeApkcm83SkhaUDFPTzZRaFRKUjc0NkJRcjZ1Yml6U2RJQURzNVV1K1Bpa1J4eStPdnhqcWpMWGlMZjY5c2pzRXYwCmtpbzM2clc5bytONS9TdHo1N0dOODliWllGMUwyc01PdkZvL0F1bDJ6N2tiQWdNQkFBRT0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tCg=='
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
} else if (process.env.REACT_APP_ENVIRONMENT === 'prod-bus') {
    api_gateway = 'https://ycnld6l4mk-vpce-0c07ac52c783d7ec9.execute-api.us-east-1.amazonaws.com/prod';
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
} else if (process.env.REACT_APP_ENVIRONMENT === 'prod-engg') {
    api_gateway = 'https://mpx8g654u1-vpce-0e08f6ef366b5db76.execute-api.us-east-1.amazonaws.com/prod';
    public_key = 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQm9qQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FZOEFNSUlCaWdLQ0FZRUFvZ2FkY2RvSStmVmZCN1ZlcTBzcgpuaHF6MGhraUpCVkExNzhyU01Ra3lhRVNlcncxUHo2cUJiWERHRGtyTFE5WnhwSWhMTU1sdWJZRkJENnU3cW1wCm1EaXRqWitmZnkwMVhLOFhBd1ZQSHh0UHhvUm5wWWpoMVlOdFg4QmNGTkNnRWJ1R0Z1K2NqTUxsRGU3OXNOZFYKQjVIdzlSbnpRdCtJYldyRlhEVmkzNEVzTGl2ajhzRkIvUmpNMkVQY1I1OExGMG1CMGx3eG9Vc3hCcXRDVnR0agp4YzBCTmJ1bEVEcjdnL24xSkp3QllLTUpDdUthQVY0cFRRYmJSbTZ6Vk1YRmJOSXNSdjYveXBlcUJvM3h0T2ZXCldUeEZGNm91eG5yS213NE5jdUNwSlY3YkMyNFdLQkMxNFFZbXVmYThuYnJmL1FHUlc0WGw5Y2xQa1I4TXhzek8KWUIybDdlN09YTnloOS85eDNncmlTMVB5eGxJcUVFclBxMHB6YU85cnZxNXJQVnRWWmYyMlJQcU56S1BUbHRIQgpyd1h6UndUeWxMTWJnUzgvRmVScnVDRm16cDlMT0xYK2V6Z0FIWDdEQTlFTkhCOUFGbXhhRlE2cHdybDJBaVFlCmg3Y3NjOW15Nm91V0Myd1RrRUNBWVlUTmwvOHM5dTh4ZGk2YmFBRDhCOTdqQWdNQkFBRT0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t'
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
}

export const environmentInfo = {
    API_GATEWAY: api_gateway,
    passwordPolicies: policies,
    publicKey: public_key,
    publicKeyPem : public_key_pem
}

export default environmentInfo;