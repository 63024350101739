import React from 'react';
import { useSnackbar } from 'notistack';

export const useErrorHandler = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [noCertificate, setNoCertificate] = React.useState('');
    const [message, setMessage] = React.useState('');

    const handleError = (error) => {
        let message = error?.message;
        let detail = error?.detail;
        let errorMsg = error?.error;
        if (message) {
            enqueueSnackbar(message, {
                variant: 'error',
            })
        } else if (detail) {
            enqueueSnackbar(detail, {
                variant: 'error',
            })
        } else if (errorMsg) {
            enqueueSnackbar(errorMsg, {
                variant: 'error',
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
            })
        }
    }

    const handleErrorInChainspec = (error, chainSpec) => {
        let message = error?.message;
        let detail = error?.detail;
        let errorMessage = error?.error;
        if (chainSpec.includes('.')) {
            if (message) {
                enqueueSnackbar(message, {
                    variant: 'error',
                })
            } else if (detail) {
                enqueueSnackbar(detail, {
                    variant: 'error',
                })
            } else {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                })
            }
        } else {
            let info = 'Certificate chain is not applicable for the root certificate.'
            setMessage(info)
        }
    }

    const handleErrorInApprovals = (error, transactionState) => {
        let message = error?.message;
        let detail = error?.detail;
        let errorMessage = error?.error;
        let errorString = (typeof error === 'string');
        let errorObject = (typeof error === 'object');
        if (message) {
            if (transactionState === 'PENDING') {
                setNoCertificate(message);
            } else {
                enqueueSnackbar(message, {
                    variant: 'error',
                })
            }
        } else if (detail) {
            enqueueSnackbar(detail, {
                variant: 'error',
            })
        } else if (errorMessage) {
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            })
        } else if (!message && !detail && !errorMessage && !errorString && errorObject) {
            enqueueSnackbar('Unhandled exception.', {
                variant: 'error',
            })
        } else {
            enqueueSnackbar(errorString, {
                variant: 'error',
            })
        }
    }

    return {
        handleError,
        handleErrorInChainspec,
        handleErrorInApprovals,
        noCertificate,
        message
    };
}
