import environmentInfo from '../../config';
import forge from 'node-forge';

const PUBLIC_KEY = environmentInfo.publicKey;

export const encryptPassword = (password) => {
    try{
        const decodedPublicPem = atob(PUBLIC_KEY);
        
        // Parse the public key
        const publicKey = forge.pki.publicKeyFromPem(decodedPublicPem);

        // Encrypt the password using RSA-OAEP with SHA-256
        const encrypted = publicKey.encrypt(password, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
        });

        // Encode the encrypted data to Base64 for safe transmission
        return forge.util.encode64(encrypted);
    
    } catch (error) {
        console.error('Encryption failed: ', {error});
        throw new Error('Sign in request failed due to failure in encryption of password');
    }
};