
export const auth0Config = {
    client_id: 'XmminWIs0S8gR3gIRBydYLWbF58x81vK',
    domain: 'matx.us.auth0.com',
}

let api_gateway = '';
let pool_data = {};
let policies = [];

if (process.env.REACT_APP_ENVIRONMENT === 'test') {
    api_gateway = 'https://cca-api-test.enphaseenergy.com';
    pool_data = {
        "UserPoolId": "us-east-1_L2DNlp8x4",
        "ClientId": "3okjkrlklouhmn69orh3fpq9m5",
        "Region": "us-east-1"
    }
} else if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    api_gateway = 'https://cca-api-dev.enphaseenergy.com';
    pool_data = {
        "UserPoolId": "us-east-1_QRZoyvD0W",
        "ClientId": "59cp536ul64a8vtkqadit3u8up",
        "Region": "us-east-1"
    }
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
} else if (process.env.REACT_APP_ENVIRONMENT === 'qa') {
    // api_gateway = 'https://cca-api-qa.enphaseenergy.com';
    api_gateway = 'https://muensyds37-vpce-0b83d958ffbf00ba8.execute-api.us-east-1.amazonaws.com/cca_qa';
    pool_data = {
        "UserPoolId": "us-east-1_eiSLzsJzX",
        "ClientId": "3s6437q4l0vv3b61rvsscq9dbd",
        "Region": "us-east-1"
    }
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
} else if (process.env.REACT_APP_ENVIRONMENT === 'prod-bus') {
    api_gateway = 'https://ycnld6l4mk-vpce-0c07ac52c783d7ec9.execute-api.us-east-1.amazonaws.com/prod';
    pool_data = {
        "UserPoolId": "us-east-1_jHkqzQpHV",
        "ClientId": "2esaaqusmub6a6fpc041aaksce",
        "Region": "us-east-1"
    }
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
} else if (process.env.REACT_APP_ENVIRONMENT === 'prod-engg') {
    api_gateway = 'https://mpx8g654u1-vpce-0e08f6ef366b5db76.execute-api.us-east-1.amazonaws.com/prod';
    pool_data = {
        "UserPoolId": "us-east-1_YRwvoTKXq",
        "ClientId": "63dcf8msajshltoed82piq5791",
        "Region": "us-east-1"
    }
    policies.push('Minimum 8 characters', '1 Uppercase', '1 Lowercase', '1 Special character');
}

export const environmentInfo = {
    poolData: pool_data,
    API_GATEWAY: api_gateway,
    passwordPolicies: policies,
}

export default environmentInfo;