import JwtLogin from './login/JwtLogin';
import JwtLoginYubikey from './login/JwtLoginYubikey';
import Yubikey from '../dashboard/Yubikey';
import JwtTokenShow from './login/JwtTokenShow';

const sessionRoutes = [
    {
        path: '/session/signin',
        component: JwtLoginYubikey,
    },
    {
        path: '/session/with-password/jwt',
        component: JwtLogin,
    },
    {
        path: '/yubikey',
        component: Yubikey,
    },
    {
        path: '/session/with-password',
        component: JwtLogin,
    },
    {
        path: '/jwt-token',
        component: JwtTokenShow,
    }
]

export default sessionRoutes;
