// import React, { useState, useEffect } from 'react'
// import { Card, Grid, Button, CircularProgress, Popover } from '@material-ui/core'
// import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
// import { makeStyles } from '@material-ui/core/styles'
// import clsx from 'clsx'
// import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
// import { encode, decode } from './base64ArrayBuffer'
// import useAuth from 'app/hooks/useAuth'
// import { useSnackbar } from 'notistack'
// import { authenticateUser, getCurrentUser, changePassword } from '../../../cognitoAuth/Cognito'
// import { useLocation } from 'react-router-dom'
// import localStorageService from 'app/services/localStorageService'
// import { RedirectPath, SourcePath } from 'app/constants'
// import environmentInfo from '../../../../config'
// import { useErrorHandler } from 'app/services/showErrors'

// let poolData = environmentInfo.poolData;
// const poolData = {
//     ClientId: process.env.REACT_APP_CLIENT_ID,
//     UserPoolId: process.env.REACT_APP_USER_POOL_ID,
//     Region: process.env.REACT_APP_REGION,
// };

// const useStyles = makeStyles(() => ({
//     cardHolder: {
//         background: '#F5F5F7',
//     },
//     card: {
//         maxWidth: 800,
//         borderRadius: 12,
//         margin: '1rem',
//     },
//     buttonProgress: {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     },
//     textAlignCenter: {
//         textAlign: 'center'
//     },
//     buttonRight: {
//         float: 'right'
//     },
//     pswdPolicy: {
//         borderBottom: '1px solid #cfcfcf',
//         textShadow: '2px 2px 10px #c7c7c7',
//         padding: '15px'
//     },
//     policyList: {
//         textShadow: '2px 2px 10px #c7c7c7',
//         listStyleType: 'circle'
//     }
// }))

// const JwtLoginYubikey = () => {
//     const [loading, setLoading] = useState(false)
//     const [userInfo, setUserInfo] = useState({
//         email: '',
//         password: '',
//         newPassword: '',
//         name: '',
//     })
//     const classes = useStyles()
//     const { login } = useAuth()
//     const { enqueueSnackbar, closeSnackbar } = useSnackbar();
//     const path = localStorageService.getItem(RedirectPath);
//     const [redirectPath, setRedirectPath] = useState('');
//     const { handleError } = useErrorHandler()
//     const { pathname } = useLocation();
//     const [userAttributes, setUserAttributes] = useState({});
//     const [yubikeyUser, setYubikeyUser] = useState({});
//     const [isForceToReset, setIsForceToReset] = useState(false);

//     localStorageService.setItem(SourcePath, pathname);

//     useEffect(() => {
//         closeSnackbar();
//         path === null ? setRedirectPath('/dashboard/default') : setRedirectPath(path);
//     }, []); // eslint-disable-line

//     const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
//         Username: userInfo.email,
//         Pool: new AmazonCognitoIdentity.CognitoUserPool({
//             UserPoolId: poolData.UserPoolId, ClientId: poolData.ClientId
//         }),
//     });

//     const [anchorEl, setAnchorEl] = useState(null);

//     const handleOpen = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     const open = Boolean(anchorEl);
//     const id = open ? 'simple-popover' : undefined;

//     const handleResetFormSubmit = async (event) => {
//         setLoading(true);
//         // delete the email verified tag
//         delete userAttributes.email_verified;
//         let requiredAttributes = userAttributes;
//         // add the name attribute
//         requiredAttributes.name = userInfo.name;
//         changePassword(yubikeyUser, requiredAttributes, userInfo.newPassword, async (err, result) => {
//             if (err) {
//                 setLoading(false);
//                 handleError(err);
//                 return;
//             } else {
//                 setLoading(false);
//                 setIsForceToReset(false);
//                 getCurrentUser(async (result) => {
//                     cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
//                     cognitoUser.initiateAuth(authenticationDetails, authCallBack);
//                 })
//             }
//         })
//     }

//     const authCallBack = {
//         onSuccess: async function (result) {
//             await login(result)
//             let url = window.location.protocol + '//' + window.location.hostname + redirectPath
//             if (window.location.port) {
//                 url = (url + ':' + window.location.port + redirectPath)
//             }
//             window.location.replace(url)
//         },
//         customChallenge: async function (challengeParameters) {
//             var signinOptions = {
//                 "challenge": decode(challengeParameters.challenge),
//                 "timeout": 1800000,
//                 "rpId": window.location.host,
//                 "userVerification": "preferred",
//                 "allowCredentials": [
//                     {
//                         "id": decode(challengeParameters.credentialid),
//                         "type": "public-key",
//                         "transports": ["ble", "nfc", "usb", "internal"]
//                     }
//                 ]
//             }
//             const cred = await navigator.credentials.get({
//                 publicKey: signinOptions
//             });
//             const credential = {};
//             if (cred.response) {
//                 const clientDataJSON = encode(cred.response.clientDataJSON);
//                 const authenticatorData = encode(cred.response.authenticatorData);
//                 const signature = encode(cred.response.signature);
//                 const userHandle = encode(cred.response.userHandle);
//                 credential.response = { clientDataJSON, authenticatorData, signature, userHandle };
//             }
//             cognitoUser.sendCustomChallengeAnswer(JSON.stringify(credential), this);
//         },
//         onFailure: function (err) {
//             console.error("Error authenticateUser:" + err);
//             if (err.message === `Cannot read properties of undefined (reading 'length')`) {
//                 enqueueSnackbar('This user has no Yubikey authentication.', {
//                     variant: 'error',
//                 })
//             } else {
//                 enqueueSnackbar(err.message, {
//                     variant: 'error',
//                 })
//             }
//         }
//     }

//     const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
//         Username: userInfo.email,
//         Password: userInfo.password
//     });
    
//     const signin = async () => {
//         setLoading(true)
//         try {
//             await authenticateUser(userInfo.email, userInfo.password, (err, result) => {
//                 if (err) {
//                     setLoading(false)
//                     enqueueSnackbar(err.message, {
//                         variant: 'error',
//                     })
//                     return
//                 } else if (result.forceToReset) {
//                     setLoading(false);
//                     // show the force to reset form
//                     setIsForceToReset(true);
//                     setYubikeyUser(result.cognitoUser);
//                     setUserAttributes(result.userAttributes);
//                 } else {
//                     setLoading(false)
//                     cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
//                     cognitoUser.initiateAuth(authenticationDetails, authCallBack);
//                 }
//             })
//         } catch (error) {
//             handleError(error);
//         }
//     }

//     const handleChange = ({ target: { name, value } }) => {
//         let temp = { ...userInfo }
//         temp[name] = value
//         setUserInfo(temp)
//     }

//     return (
//         <div className={clsx(
//             'flex justify-center items-center  min-h-full-screen',
//             classes.cardHolder
//         )}>
//             <Card className={classes.card}>
//                 <Grid container>
//                     <Grid item lg={12} md={12} sm={12} xs={12}>
//                         <div className={`p-8 bg-light-gray ${classes.textAlignCenter}`}>
//                             <img
//                                 className="w-200"
//                                 src="https://www4.enphase.com/sites/all/themes/enphase/assets/images/svgs/src/enphase-logo.svg"
//                                 alt=""
//                             />
//                         </div>
//                         {isForceToReset ?
//                             <div>
//                                 <h4 className={`bg-light-gray ${classes.textAlignCenter}`}>
//                                     Force to reset your password
//                                 </h4>
//                                 <div className='p-8 h-full bg-light-gray relative'>
//                                     <ValidatorForm onSubmit={handleResetFormSubmit} container='true' onError={() => null} autoComplete='off'>
//                                         <TextValidator
//                                             className='mb-6 w-full min-w-288'
//                                             variant='outlined'
//                                             size='small'
//                                             label='Email'
//                                             onChange={handleChange}
//                                             type='email'
//                                             name='email'
//                                             value={userInfo.email}
//                                             validators={['required', 'isEmail']}
//                                             errorMessages={['This field is required', 'Email is not valid']}
//                                         />
//                                         <TextValidator
//                                             className='mb-6 w-full min-w-288'
//                                             label='Old Password'
//                                             variant='outlined'
//                                             size='small'
//                                             onChange={handleChange}
//                                             name='password'
//                                             type='password'
//                                             value={userInfo.password}
//                                             validators={['required']}
//                                             errorMessages={['This field is required']}
//                                         />
//                                         <TextValidator
//                                             className='mb-6 w-full min-w-288'
//                                             label='New Password'
//                                             variant='outlined'
//                                             size='small'
//                                             onChange={handleChange}
//                                             onClick={handleOpen}
//                                             name='newPassword'
//                                             type='password'
//                                             value={userInfo.newPassword}
//                                             validators={['required']}
//                                             errorMessages={['This field is required']}
//                                         />
//                                         <Popover
//                                             className='ml-10'
//                                             id={id}
//                                             open={open}
//                                             anchorEl={anchorEl}
//                                             onClose={handleClose}
//                                             PaperProps={{ elevation: 10 }}
//                                             disableAutoFocus={true}
//                                             disableEnforceFocus={true}
//                                             anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
//                                             transformOrigin={{ vertical: 'center', horizontal: 'left' }}
//                                         >
//                                             <div>
//                                                 <p className={classes.pswdPolicy}>New password should contain</p>
//                                                 {environmentInfo?.passwordPolicies.map((policy, index) => (
//                                                     <ul key={index} className={classes.policyList}>
//                                                         <li>{policy}</li>
//                                                     </ul>
//                                                 ))}
//                                             </div>
//                                         </Popover>
//                                         <TextValidator
//                                             className='mb-6 w-full min-w-288'
//                                             label='Name'
//                                             variant='outlined'
//                                             size='small'
//                                             onChange={handleChange}
//                                             name='name'
//                                             type='text'
//                                             value={userInfo.name}
//                                             validators={['required']}
//                                             errorMessages={['This field is required']}
//                                         />
//                                         <div className={`flex flex-wrap items-center mb-6 ${classes.buttonRight}`}>
//                                             <div className='relative'>
//                                                 <Button
//                                                     variant='contained'
//                                                     color='primary'
//                                                     disabled={loading}
//                                                     type='submit'
//                                                 >
//                                                     Change Password
//                                                 </Button>
//                                                 {loading && (
//                                                     <CircularProgress size={24} className={classes.buttonProgress} />
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </ValidatorForm>
//                                 </div>
//                             </div>
//                             :
//                             <div>
//                                 <h4 className={`bg-light-gray ${classes.textAlignCenter}`}>
//                                     Sign in as cognito user
//                                 </h4>
//                                 <div className='p-8 h-full bg-light-gray relative'>
//                                     <ValidatorForm onSubmit={signin} container='true' onError={() => null} autoComplete='off'>
//                                         <TextValidator
//                                             className='mb-6 w-full min-w-288'
//                                             variant='outlined'
//                                             size='small'
//                                             label='Email'
//                                             onChange={handleChange}
//                                             type='email'
//                                             name='email'
//                                             value={userInfo.email}
//                                             validators={['required', 'isEmail']}
//                                             errorMessages={['This field is required', 'Email is not valid']}
//                                         />
//                                         <TextValidator
//                                             className='mb-6 w-full min-w-288'
//                                             label='Password'
//                                             variant='outlined'
//                                             size='small'
//                                             onChange={handleChange}
//                                             name='password'
//                                             type='password'
//                                             value={userInfo.password}
//                                             validators={['required']}
//                                             errorMessages={['This field is required']}
//                                         />
//                                         <div className={`flex flex-wrap items-center mb-6 ${classes.buttonRight}`}>
//                                             <div className='relative'>
//                                                 <Button
//                                                     variant='contained'
//                                                     color='primary'
//                                                     type='submit'
//                                                     disabled={loading}
//                                                 >
//                                                     Sign In
//                                                 </Button>
//                                                 {loading && (
//                                                     <CircularProgress size={24} className={classes.buttonProgress} />
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </ValidatorForm>
//                                 </div>
//                             </div>
//                         }
//                     </Grid>
//                 </Grid>
//             </Card>
//         </div>
//     )
// }

// export default JwtLoginYubikey
