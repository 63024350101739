import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const objectsRoutes = [
    {
        path: '/objects',
        exact: true,
        component: React.lazy(() => import('./Objects'))
    },
    {
        path: '/objects/viewObject/:objectId',
        component: React.lazy(() => import('./ViewObject'))
    },
    {
        path: '/objects/signNewObject',
        component: React.lazy(() => import('./SignNewObject'))
    },
    {
        path: '/objects/signNewObjectStatus/:objectId',
        component: React.lazy(() => import('./SignNewObjectStatus'))
    },
]

export default objectsRoutes
