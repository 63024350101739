import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const crlRoutes = [
    {
        path: '/crlList',
        exact: true,
        component: React.lazy(() => import('./CRLList'))
    },
    {
        path: '/crlList/generateCRL',
        component: React.lazy(() => import('./GenerateCRL'))
    },
    {
        path: '/crlList/crlStatus/:crlId',
        component: React.lazy(() => import('./CrlStatus'))
    },
    {
        path: '/crlList/viewDetails/:transactionId',
        component: React.lazy(() => import('./ViewDetails'))
    }
]

export default crlRoutes
