import React from 'react'
import { Redirect } from 'react-router-dom'
import dashboardRoutes from './views/dashboard/DashboardRoutes'
import signersRoutes from './views/signers/SignersRoutes'
import identitiesRoutes from './views/identities/IdentitiesRoutes'
import objectsRoutes from './views/objects/ObjectsRoutes'
import matxSearchBoxRoutes from './components/MatxSearchBox/MatxSearchBoxRoutes'
import revocationsRoutes from './views/revocations/RevocationsRoutes'
import crlRoutes from './views/crl/CRLRoutes'
import transactionsRoutes from './views/transactions/TransactionsRoutes'
import workflowsRoutes from './views/workflows/WorkfolwsRoutes'

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/dashboard/default' />,
    },
    {
        path: '/utilities',
        component: () => <Redirect to='/utilities' />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to='/dashboard/default' />,
    },
]

const routes = [
    ...dashboardRoutes,
    ...signersRoutes,
    ...identitiesRoutes,
    ...objectsRoutes,
    ...revocationsRoutes,
    ...crlRoutes,
    ...transactionsRoutes,
    ...workflowsRoutes,
    ...matxSearchBoxRoutes,
    ...redirectRoute,
    ...errorRoute,
]

export default routes
