import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const matxSearchBoxRoutes = [
    {
        path: '/searchCertificates',
        exact: true,
        component: React.lazy(() => import('./SearchCertificates'))
    },
    {
        path: '/searchCertificates/:searchParam',
        exact: true,
        component: React.lazy(() => import('./SearchCertificates'))
    },
    {
        path: '/searchCertificates/viewChain/:chainSpec',
        component: React.lazy(() => import('./ViewChain'))
    },
    {
        path: '/searchCertificates/:searchParam/viewChain/:chainSpec',
        component: React.lazy(() => import('./ViewChain'))
    }
]

export default matxSearchBoxRoutes
