import React, { useEffect, useState, } from 'react'
import { Button, Dialog, DialogContentText, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'
import 'reactjs-popup/dist/index.css';
import localStorageService from 'app/services/localStorageService';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import 'reactjs-popup/dist/index.css';
import { green } from '@material-ui/core/colors';
import Cookies from 'js-cookie'

function CopyButton({jwtToken}) {

    const [isCopied,setIsCopied] = useState(false)
    

    return (
      <div className="App" style={{paddingTop: '12px'}}>
        <CopyToClipboard 
        text={jwtToken}
        onCopy={ () => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000)
        }}>
            <IconButton>{isCopied ? <CheckCircleIcon sx={{color: green[500]}}/> : <ContentCopyIcon/>}</IconButton>
        </CopyToClipboard>
      </div>
    );
}

function JwtTokenShow(){
    const jwtToken = Cookies.get('accessToken');
    const [open, setOpen] = React.useState(true);
 
    const handleToClose = () => {
        setOpen(false);
        window.location.reload()
    };

    const showJwtToken = localStorageService.getItem('showJwtToken')
    useEffect(() => {
        const firstLoadDone = localStorageService.getItem('firstLoadDone')
        if (firstLoadDone) {
            // If it's the first load, set the flag in local storage to true and reload the page
            localStorageService.setItem('showJwtToken', false);
            let url = window.location.protocol + '//' + window.location.hostname
            if (window.location.port) {
                url = (url + ':' + window.location.port);
            }
            url += '/dashboard/default'
            window.location.replace(url)
        }else{
            localStorageService.setItem('firstLoadDone', true)
        }
    }, [])
 
    return (
        <>
        { showJwtToken &&
        <div stlye={{}}>
            <Dialog open={open} onClose={handleToClose} >
                <div style={{display: 'flex', direction: 'flex-row', justifyContent: 'space-between', paddingRight: '10px'}}>
                    <DialogTitle>{"Jwt Token"}</DialogTitle>
                    <CopyButton jwtToken={jwtToken}/>
                </div> 
                <div>
                    <DialogContent style={{ backgroundColor: '#f4f6fa', height: '12em'}}>
                        <DialogContentText style={{color: 'grey', overflowWrap: 'break-word'}}>
                            {jwtToken}
                        </DialogContentText>
                    </DialogContent>
                </div>
                <DialogActions>
                    <Button onClick={handleToClose}
                        color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>}
        </>
    );
 }

 export default JwtTokenShow