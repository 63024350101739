// import { TimeoutError } from '@auth0/auth0-spa-js'
import axios from 'axios'
import localStorageService from '../services/localStorageService'
import { CognitoIdTokenKey, CognitoAccessTokenKey } from '../constants'
import environmentInfo from '../../config'

let API_GATEWAY = environmentInfo.API_GATEWAY;
let poolData = environmentInfo.poolData;

let idToken = localStorageService.getItem(CognitoIdTokenKey);
let accessToken = localStorageService.getItem(CognitoAccessTokenKey);

axios.interceptors.request.use(
    (request) => {
        request.headers.Authorization = idToken;
        request.headers.cca_access = accessToken;
        request.headers.cca_appClientId = poolData.ClientId;
        request.headers.cca_userPoolId = poolData.UserPoolId;
        request.headers.cca_userPoolRegion = poolData.Region;
        request.headers.withCredential = true;
        request.headers.Content_Security_Policy = `frame-ancestors 'self'`;
        request.headers.X_Frame_Options = 'SAMEORIGIN';
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (res) => {
        // Add configurations here
        if (res.status === 201) {
            console.log('Posted Successfully');
        }
        return res;
    },
    (err) => {
        // if(err.response.data && err.response.data.message && err.response.data.message == "Unauthorized") {
        //     history.push('/session/signin')
        // }
        return Promise.reject(err.response.data);
    }
);

export const signersList = async (filters) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/signer`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const identitiesBySigner = async (filters) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/identity`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const signObject = async (objData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/obj`, objData)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const confirmObject = async (transactionId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/obj/${transactionId}/confirm`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const viewObjectApprovalDetails = async (objectId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/object/${objectId}/approval`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const workflowApprovals = async (transactionId, taskId, approvalData) => {
    return new Promise((resolve, reject) => {
        axios.put(`${API_GATEWAY}/cca/workflow/${transactionId}/task/${taskId}`, approvalData)
            .then(response => {
                return resolve(response.config.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const getSignersBasedOnSubtype = async (objSubType) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/signing-transaction/right/${objSubType}/signer`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const addAttachments = async (file, transactionId) => {
    let formData = new FormData();
    formData.append("file", file);
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/obj/${transactionId}/attachment`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const searchIdentity = (filters) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/identity`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const objectsList = async (filter) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/object`,
            params: filter
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const getRequestJson = async (objectId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/object/${objectId}/request-json`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const getOutputJson = async (objectId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/object/${objectId}/output-json`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const getAttachments = async (objectId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/object/${objectId}/attachment`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const viewAttachment = async (attachment) => {
    let attachmentId = attachment.attachmentId
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/object/attachment/${attachmentId}/file`, { responseType: 'blob' })
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const identitiyApprovalDetails = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/approval`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const requestDetails = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/request-json`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const viewX509Pretty = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/certificate/x509v3/pretty`)
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const viewCertificate = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/certificate/lpki/pretty`)
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const viewCertificateChain = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/certificate-chain/lpki/pretty`)
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const downloadX509 = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/certificate/x509v3/file`)
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const downloadCertificate = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/certificate/lpki/file`, { responseType: 'blob' })
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const downloadCertificateChain = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity/${chainSpec}/certificate-chain/lpki/file`, { responseType: 'blob' })
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const identitiesList = (filters) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/identity`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const callMiddlewareForSessionIdLogging = async (ccaToken) => {
    return await fetch(`${API_GATEWAY}/cca/cognito/session/log`, {
            method: 'POST',
            headers: {
                'Authorization': ccaToken,
		'Content-Type': 'application/json'
            },
        });
};

export const identitySigners = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/signing-transaction/csr/signer`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const identityRights = (value) => {
    let chainSpec = value.chainSpec
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/signing-transaction/csr/right/${chainSpec}`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const createNewIdentity = (csrData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/csr`, csrData)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const confirmCertificateSigning = (transactionId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/csr/${transactionId}/confirm`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const importIdentities = async (file) => {
    let formData = new FormData()
    formData.append("file", file)
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/csr/import`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const importIdentitiesList = async (filters, transactionId) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/identity?transactionId=${transactionId}`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const dashboardDetails = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/user/dashboard`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const revocationList = async (filters) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/identity?revoked=yes`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const crlList = async (filters) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/crl`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const searchChainspec = async (chainSpec) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/identity?chainspec=${chainSpec}`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const revokeACertificate = async (chainSpec, revoke) => {
    return new Promise((resolve, reject) => {
        axios.put(`${API_GATEWAY}/cca/identity/${chainSpec}`, revoke)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const crlSigner = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/signing-transaction/crl/signer`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const crlGenerate = async (crlData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/crl`, crlData)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const crlConfirm = async (transactionId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/signing-transaction/crl/${transactionId}/confirm`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const crlApprovalDetails = async (transactionId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/crl/${transactionId}/approval`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const crlView = async (transactionId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/crl/${transactionId}/certificate/lpr/pretty`)
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const crlDownload = async (transactionId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/crl/${transactionId}/certificate/lpr/file`, { responseType: 'blob' })
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const getPublickey = async (publicKeyData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/user/publickey`, publicKeyData)
            .then((response) => {
                return resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const workflowsList = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/workflow`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const createWorkflow = async (workflowData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/workflow`, workflowData)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const configureWorkflow = async (chainSpec, workflowId) => {
    return new Promise((resolve, reject) => {
        let url;
        if (workflowId) {
            url = `${API_GATEWAY}/cca/signer/${chainSpec}?workflow_id=${workflowId}`
        } else {
            url = `${API_GATEWAY}/cca/signer/${chainSpec}`
        }
        axios.put(url)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const viewWorkflow = async (workflowId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/workflow/${workflowId}`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const userGroup = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/cognito/group`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const signerGroups = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/signer-group`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const cognitoUsers = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_GATEWAY}/cca/cognito/user`)
            .then((response) => {
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const transactionsList = async (filters) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${API_GATEWAY}/cca/workflow/approval`,
            params: filters
        })
            .then((response) => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

export const clearCognitoCache = () => {
    return new Promise((resolve, reject) => {
        axios.put(`${API_GATEWAY}/cca/cognito/clearCache`)
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error);
            })
    })
}

export const revokeToken = (refreshToken) => {
    return new Promise((resolve, reject) => {
        axios.post(`${API_GATEWAY}/cca/cognito/revokeToken`, refreshToken)
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error);
            })
    })
}
