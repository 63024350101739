import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const signersRoutes = [
    {
        path: '/signers',
        exact: true,
        component: React.lazy(() => import('./Signers'))
    },
    {
        path: '/signers/:chainSpec',
        exact: true,
        component: React.lazy(() => import('./ViewChain'))
    },
    {
        path: '/signers/:chainSpec/identities',
        component: React.lazy(() => import('./ViewIdentities'))
    }
]

export default signersRoutes
