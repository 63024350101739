import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import localStorageService from '../services/localStorageService'
import { CognitoIdTokenPayLoadKey, CognitoIdTokenKey, UserRole, RedirectPath, SourcePath, CognitoAccessTokenKey, CognitoRefreshTokenKey } from '../constants'
import { revokeToken } from 'app/services/awsServices'

const getSourcePath = localStorageService.getItem(SourcePath)

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    isPath: false,
    source: getSourcePath
}

const isValidToken = (accessToken, userDetails) => {
    if (!accessToken) return false;
    const currentTime = Date.now() / 1000
    return userDetails.payload.exp > currentTime;
}

const setUserSession = (user) => {
    if (user) {
        localStorageService.setItem(CognitoIdTokenPayLoadKey, user)
        localStorageService.setItem(UserRole, user.role)
    } else {
        localStorageService.removeItem(CognitoIdTokenPayLoadKey)
        localStorageService.removeItem(UserRole)
    }
}

const formUserObject = (userDetails) => {
    return {
        id: userDetails.jwtToken,
        role: userDetails.payload['custom:role'],
        name: userDetails.payload.name,
        email: userDetails.payload.email,
        avatar: '/assets/images/face-6.jpg'
    }
}

const setSession = (idToken) => {
    if (idToken) {
        // const decodedToken = jwt_decode(idToken)
        localStorageService.setItem(CognitoIdTokenKey, idToken)

        // removed bearer keyword from the authorization header
        axios.defaults.headers.common.Authorization = `${idToken}`
    } else {
        localStorageService.removeItem(CognitoIdTokenKey)
        localStorageService.removeItem(CognitoAccessTokenKey)
        localStorageService.removeItem(CognitoRefreshTokenKey)
        localStorageService.removeItem(RedirectPath)
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                isPath: true,
                source: getSourcePath
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (result) => {
        let accessToken = result.accessToken.jwtToken;
        let refreshToken = result.refreshToken.token;
        localStorageService.setItem(CognitoAccessTokenKey, accessToken);
        localStorageService.setItem(CognitoRefreshTokenKey, refreshToken);
        //  commenting default token
        // let accessToken = result.getAccessToken().getJwtToken();
        let userDetails = result.idToken;
        // form user object based on the ui template
        var user = formUserObject(userDetails)
        // set user details in local storage

        setUserSession(userDetails)

        // set id token as token for authorizing the user
        setSession(userDetails.jwtToken)
        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        const refreshToken = localStorageService.getItem(CognitoRefreshTokenKey);
        let revokeData = {
            token: refreshToken
        }
        revokeToken(revokeData)
        setUserSession(null)
        setSession(null)
        setTimeout(() => {
            dispatch({ type: 'LOGOUT' })
        }, 1000)
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = localStorageService.getItem(CognitoIdTokenKey);
                var userDetails = localStorageService.getItem(CognitoIdTokenPayLoadKey);
                localStorageService.setItem(UserRole, userDetails.payload['custom:role']);
                if (accessToken && userDetails && isValidToken(accessToken, userDetails)) {
                    setSession(accessToken)
                    // const response = await axios.get('/api/auth/profile')
                    // const { user } = response.data

                    const user = formUserObject(userDetails);
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
