import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const workflowsRoutes = [
    {
        path: '/workflows',
        exact: true,
        component: React.lazy(() => import('./Workflows'))
    },
    {
        path: '/workflows/create',
        component: React.lazy(() => import('./CreateWorkflow'))
    },
    {
        path: '/workflows/:workflowId',
        component: React.lazy(() => import('./ViewWorkflow'))
    }
]

export default workflowsRoutes
