import React from 'react'
// import { authRoles } from '../../auth/authRoles'

const revocationsRoutes = [
    {
        path: '/revocations',
        exact: true,
        component: React.lazy(() => import('./Revocations'))
    },
    {
        path: '/revocations/revokeCertificate',
        component: React.lazy(() => import('./RevokeCertificate'))
    },
    {
        path: '/revocations/:chainSpec',
        component: React.lazy(() => import('./ViewChain'))
    }
]

export default revocationsRoutes
