import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import environmentInfo from '../../config'
import { callMiddlewareForSessionIdLogging } from '../services/awsServices'

let poolData = environmentInfo.poolData;
const userPool = new CognitoUserPool(poolData)

export const createUser = (name, email, password, callback) => {
    // add attributes based on the order in cognito
    const attributeList = [
        new CognitoUserAttribute({
            Name: 'name',
            Value: name,
        }, {
            Name: 'custom:username',
            Value: name
        }),
    ]
    // Username must be unique in a pool, and cant be a valid email format
    // To log in with email, make sure it is set as an alias attribute in Cognito
    // More info: http://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-usernames
    userPool.signUp(email, password, attributeList, null, callback)
}

export const verifyUser = (username, verifyCode, callback) => {
    const userData = {
        Username: username,
        Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.confirmRegistration(verifyCode, true, callback)
}

export const forgotPassword = (email, callback) => {
    const userData = {
        Username: email,
        Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.forgotPassword({
        onSuccess: result => {
            callback(null, result)
        },
        onError: err => {
            callback(err)
        }
    })
}

export const authenticateUser = (email, password, callback) => {
    const authData = {
        Username: email,
        Password: password,
    }
    const authDetails = new AuthenticationDetails(authData)
    const userData = {
        Username: email,
        Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {
            // console.log('access token + ' + result.getAccessToken().getJwtToken())
            let response = {
                forceToReset: false,
                cognitoResponse: result
            }

            try {
                const apiResponse = callMiddlewareForSessionIdLogging(result.getAccessToken().getJwtToken())
                console.log('Sent token to CCA_Middleware:', apiResponse.data);
            } catch (apiError) {
                console.error('Error sending token to CCA_Middleware:', apiError);
            }

            callback(null, response)
        },
        onFailure: err => {
            callback(err)
        },
        newPasswordRequired: userAttributes => {
            let response = {
                forceToReset: true,
                userAttributes: userAttributes,
                cognitoUser: cognitoUser,
            }
            callback(null, response)
            // delete userAttributes.email_verified;
            // cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
        }
    })
}

export const changePassword = (cognitoUser, userAttributes, newPassword, callback) => {
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: result => {
            callback(null, result)
        },
        onFailure: err => {
            callback(err)
        }
    })
}

export const signOut = () => {
    userPool.getCurrentUser().signOut()
    window.location.reload()
}

export const getCurrentUser = (callback) => {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) return false;
    cognitoUser.getSession((err, session) => {
        if (err) {
            return
        }
        // console.log('Session valid?', session.isValid())
        cognitoUser.getUserAttributes((err, attributes) => {
            if (err) return;
            callback(attributes)
        })
    })
}