import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import localStorageService from '../services/localStorageService'
import {  UserRole, SourcePath, RedirectPath } from '../constants'
import { revokeToken } from 'app/services/awsServices'
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';


const getSourcePath = localStorageService.getItem(SourcePath)

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    isPath: false,
    source: getSourcePath
}

const isValidToken = (accessToken, userDetails) => {
    if (!accessToken) return false;
    const currentTime = Date.now() / 1000
    return userDetails.exp > currentTime;
}

function setTokenCookie(name, value) {
    Cookies.set(name, value, {
      expires: 720 / 1440, // 720 minutes - 12 hours expiration
      secure: true,
      sameSite: 'Strict',
    });
  }

const setUserSession = (user) => {
    if (user) {
        localStorageService.setItem(UserRole, user.role)
    } else {
        Cookies.remove('accessToken');
        Cookies.remove('idToken');
        Cookies.remove('refreshToken');
        localStorageService.removeItem(UserRole)
    }
}

const formUserObject = (idToken) => {
    const payload = jwtDecode(idToken);
    return {
        id: idToken,
        role: payload['custom:role'],
        name: payload.name,
        email: payload.email,
        avatar: '/assets/images/face-6.jpg'
    }
}

const setSession = (accessToken) => {
    if (accessToken) {
        axios.defaults.headers.common.Authorization = `${accessToken}`
    } else {
        localStorageService.removeItem(RedirectPath)
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                isPath: true,
                source: getSourcePath
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    // register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (result) => {
        try{
            var user = formUserObject(result.idToken);
            setTokenCookie('accessToken', result.accessToken);
            setTokenCookie('refreshToken', result.refreshToken);
            setTokenCookie('idToken', result.idToken);
            setUserSession(user)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
        } catch (error)  {
            Cookies.remove('accessToken');
            Cookies.remove('idToken');
            Cookies.remove('refreshToken');
            throw new Error("Authentication failed");
        }
    }


    const logout = async () => {
        try {
            const refreshToken = Cookies.get('refreshToken');
            let revokeData = {
                token: refreshToken,
            };
            await revokeToken(revokeData); // Wait for the token revocation to complete
        } catch (error) {
            console.error('Failed to revoke token:', error);
        } finally {
            setUserSession(null);
            setSession(null);
            setTimeout(() => {
                dispatch({ type: 'LOGOUT' });
            }, 1000);
        }
    };

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = Cookies.get('accessToken');
                var userDetails = Cookies.get('idToken');
                var userDetailsDecoded = jwtDecode(userDetails);
                if (accessToken && userDetails && isValidToken(accessToken, userDetailsDecoded)) {
                    setSession(accessToken)
                    const user = formUserObject(userDetails);
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
